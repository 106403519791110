import React from 'react'

const DownArrow = () => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
                <path d="M3 6V12.9221H13M13 12.9221L9.92308 9.84597M13 12.9221L9.92308 16" stroke="#FF5B45" stroke-width="2" />
            </svg>
        </div>
    )
}

export default DownArrow