'use client';
import React, { useRef, useState } from 'react';
import { SimpleLottieCard } from '../Common/LottieCard/SimpleLottie';

import Image from '../Image';
import { HomeHeroSectionType } from '@/app/(landing)/lib/types/sectionsType';
import LottieCard from '../Common/LottieCard';

import { cn } from '@/utils/cn';
import herolottie from '@/public/lottie/hero.json';
import desktopWebSearchlottie from '@/public/lottie/desktopWebSearch.json';
import desktopImagelottie from '@/public/lottie/desktopImage.json';
import heroMobile from '@/public/lottie/heroMobile.json';
import mobileWebSearchlottie from '@/public/lottie/mobileWebSearch.json';
import mobileImagelottie from '@/public/lottie/mobileImage.json';
import HomeLottie from '@/public/lottie/BackgroundLines.json';
import { Button } from '@/components/ui/button';
import { ChevronDownIcon } from 'lucide-react';

const HomeHeroSection: React.FC<HomeHeroSectionType> = (block) => {
  const { title, content, cta, navtab, subTitle } = block || {};

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const nextSectionRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };

  const navTabClasses = (isSelected: boolean) =>
    cn(
      'flex items-center gap-2 py-1.5 px-4 text-xs esm:text-sm font-medium rounded-[37px] cursor-pointer transition ease-in duration-150 text-nowrap',
      isSelected ? 'bg-[#3B3E45] !text-[#DDDFE3]' : 'text-brand-Black1'
    );

  const renderLottie = () => {
    switch (selectedIndex) {
      case 0:
        return (
          <SimpleLottieCard
            className="w-full h-full max-w-[1400px] mx-auto"
            jsonFileUrl={desktopWebSearchlottie}
            autoPlay
            selectedIndex={selectedIndex}
          />
        );
      case 1:
        return (
          <SimpleLottieCard
            className="w-full h-full max-w-[1400px] mx-auto"
            jsonFileUrl={desktopImagelottie}
            autoPlay
            selectedIndex={selectedIndex}
          />
        );
      case 2:
        return (
          <SimpleLottieCard
            jsonFileUrl={herolottie}
            className="w-full h-full max-w-[1400px] mx-auto"
            autoPlay
            selectedIndex={selectedIndex}
          />
        );
      default:
        return null;
    }
  };

  const mobileRenderLottie = () => {
    switch (selectedIndex) {
      case 0:
        return (
          <SimpleLottieCard
            className="w-full h-full max-w-[1400px] mx-auto"
            jsonFileUrl={mobileWebSearchlottie}
            autoPlay
            selectedIndex={selectedIndex}
          />
        );
      case 1:
        return (
          <SimpleLottieCard
            className="w-full h-full max-w-[1400px] mx-auto"
            jsonFileUrl={mobileImagelottie}
            autoPlay
            selectedIndex={selectedIndex}
          />
        );
      case 2:
        return (
          <SimpleLottieCard
            className="w-full h-full max-w-[1400px] mx-auto"
            jsonFileUrl={heroMobile}
            autoPlay
            selectedIndex={selectedIndex}
          />
        );
      default:
        return null;
    }
  };

  const renderNavtab = () => (
    <div className="flex justify-center items-center mt-5 em:mt-[30px] lg:mt-[52px] p-1 bg-brand-Gray2 rounded-[37px] w-fit mx-auto border-[1px] border-brand-Gray2 backdrop-blur-6 mb-2 lg:mb-0">
      {navtab?.map((item, index) => {
        const { label, image } = item || {};
        return (
          <div
            key={index}
            className={navTabClasses(selectedIndex === index)}
            onClick={() => setSelectedIndex(index)}
          >
            {image.url && (
              <Image
                src={image.url}
                width={100}
                height={100}
                alt={label || 'image'}
                className={cn(
                  'w-full max-w-[15px] h-[15px]',
                  selectedIndex === index ? 'brightness-[250]' : ''
                )}
              />
            )}
            {label}
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="mb-[70px] sm:mb-[100px] relative">
      <div className="relative overflow-hidden">
        <div className="pt-[90px] lg:pt-[100px] pb-[60px] lg:pb-[90px] relative z-[2] em:min-h-screen flex flex-col justify-center gap-1 md:gap-5">
          {/* Titles */}
          <div className="flex flex-col items-center justify-center gap-4 1xl:gap-10 animate__animated animate__fadeInUp px-5">
            <div>
              {title && (
                <h1
                  className="text-brand-Black1 text-center text-[36px] em:text-[40px] sm:text-[46px] lg:text-[58px] 1xl:text-[72px] font-Mono font-extralight  [&>span]:text-brand-Orange [&>span]:font-Mono [&>span]:font-medium leading-[138%] tracking-[-1.44px]"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
              {subTitle && (
                <h1
                  className="text-center font-extralight  [&>strong]:font-medium  font-Mono leading-[138%] text-[36px] em:text-[40px] sm:text-[46px] lg:text-[58px] 1xl:text-[72px]"
                  dangerouslySetInnerHTML={{ __html: subTitle }}
                />
              )}
            </div>
            {content && (
              <p className="text-sm em:text-base sm:text-lg text-brand-SlateGray1 font-extralight leading-[128%] text-center max-w-[494px] font-Mono">
                {content}
              </p>
            )}
          </div>
          {navtab && <div className="block md:hidden">{renderNavtab()}</div>}

          {/* Lottie Animation */}
          <div>
            {herolottie && (
              <>
                <div className="md:block hidden">{renderLottie()}</div>
                <div className="md:hidden block">{mobileRenderLottie()}</div>
              </>
            )}
            {navtab && <div className="hidden md:block">{renderNavtab()}</div>}
          </div>

          {/* CTA */}
          <div className="mt-2 1xl:mt-6 animate__animated animate__fadeInUp">
            <div className="flex justify-center items-center">
              <Button className="shiny-cta" variant={cta?.variant}>
                <span className="text-[#FDFDFD] text-base font-medium font-Mono">
                  {cta?.label}
                </span>
              </Button>
            </div>
          </div>
        </div>

        {/* Background Animation */}
        <div className="absolute inset-0">
          {HomeLottie && (
            <LottieCard
              className="w-full h-full rounded-lg [&>svg]:rounded-[10px]"
              jsonFileUrl={HomeLottie}
              loop
            />
          )}
        </div>

        {/* Scroll Icon */}
        {HomeLottie && (
          <div className="flex justify-center items-center z-[5] absolute bottom-[10px] left-0 right-0 animate-bounce-icon">
            <div className="flex justify-center items-center w-[40px] h-[40px] 1xl:w-[50px] 1xl:h-[50px] bg-zinc-200 bg-opacity-50 rounded-full">
              <ChevronDownIcon
                onClick={handleScroll}
                className="w-[30px] 1xl:w-[40px] h-[30px] 1xl:h-[40px] cursor-pointer"
              />
            </div>
          </div>
        )}
      </div>

      {/* {HomeLottie && (
        <div className="flex justify-center items-center z-[5] absolute bottom-[-27px] left-0 right-0 animate-bounce-icon">
          <Image
            onClick={handleScroll}
            alt="arrow"
            src="/images/downArrow.svg"
            width={60}
            height={60}
            className="w-[40px] 1xl:w-[60px] h-[40px] 1xl:h-[60px] cursor-pointer"
          />
        </div>
      )} */}

      <div ref={nextSectionRef} id="nextSectionId" />
    </div>
  );
};

export default HomeHeroSection;
