'use client';

import React, { useEffect, useRef } from 'react';
import dynamic from 'next/dynamic';
import { LottieRefCurrentProps } from 'lottie-react';

const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export interface SimpleLottieCardProps {
  jsonFileUrl: object | string;
  className: string;
  autoPlay?: boolean;
  loop?: boolean;
  speed?: number;
  selectedIndex?: number;
}

export const SimpleLottieCard: React.FC<SimpleLottieCardProps> = ({
  jsonFileUrl,
  className,
  autoPlay = false,
  loop = false,
  speed = 1,
  selectedIndex
}) => {
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(speed);
      lottieRef.current.goToAndPlay(0, true);
    }
  }, [selectedIndex, speed]);

  return (
    <div className={`${className} transition-opacity duration-300`}>
      <Lottie
        lottieRef={lottieRef}
        animationData={jsonFileUrl}
        autoPlay={autoPlay}
        loop={loop}
        className={className}
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
      />
    </div>
  );
};
