import React from 'react'

const MoveIcon = ({ className }: any) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none" className={className}>
            <rect x="1" y="0.5" width="39" height="39" rx="19.5" fill="white" />
            <rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="#EFF1F3" />
            <path d="M18.5 14L24.5 20L18.5 26" stroke="#FF5B45" stroke-width="1.5" />
        </svg>

    )
}

export default MoveIcon