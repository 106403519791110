'use client';
import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import Image from '@/app/(landing)/components/Image';
import PlayIcon from '@/app/(landing)/components/icons/PlayIconSvg';
import { CustomImageType } from '@/app/(landing)/lib/types/common';

const ReactPlayer = dynamic(() => import('react-player/youtube'), {
  ssr: false,
});

interface VideoPlayerProps {
  thumbnailImage: CustomImageType;
  youtubeVideoUrl: string;
}

const VideoPlayer = ({ block }: { block: VideoPlayerProps }) => {
  const [duration, setDuration] = useState<number | null>(null);

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const formatDuration = (duration: number | null): string => {
    if (!duration) return '0:00';
    const minutes = Math.floor(duration / 60);
    const seconds = Math.floor(duration % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div>
      <Dialog>
        <div className="relative w-full max-w-full lg:max-w-[413px] rounded-lg rounded-bl-[50px] overflow-hidden shadow-md">
          <Image
            src={block?.thumbnailImage}
            alt={block?.thumbnailImage?.alt}
            className="w-full max-w-full lg:max-w-[413px] h-[200px] rounded-lg rounded-bl-[50px] object-cover border-[1px] border-[#DBDCDF]"
          />
          {block?.youtubeVideoUrl && (
            <DialogTrigger className="absolute bottom-5 left-5">
              <PlayIcon className="w-[45px] md:w-[60px] h-[45px] md:h-[60px]" />
            </DialogTrigger>
          )}
          {duration !== null && (
            <div className="absolute top-2 right-2 bg-white px-2 py-[2px] rounded-[3px]">
              <span className="text-brand-DarkGray2 text-xs font-mono">
                {formatDuration(duration)}
              </span>
            </div>
          )}
        </div>

        {block?.youtubeVideoUrl && (
          <DialogContent className="!max-w-[1016px] h-[330px] lg:h-[400px] ">
            <ReactPlayer
              className="w-full max-w-full lg:max-w-[800px] h-[330px] lg:h-[400px] mx-auto"
              url={block?.youtubeVideoUrl}
              width="100%"
              height="100%"
              controls
              onDuration={handleDuration}
              playing
            />
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default VideoPlayer;
