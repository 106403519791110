import React from 'react';
import moment from 'moment';
import Image from '../Image';
import { GalleryType } from '../../lib/types/sectionsType';
import MoveIcon from '../icons/MoveIcon';

const GalleryCard: React.FC<GalleryType> = (block) => {
  const { categories, description, publishDate, title, image } = block || {};

  const timeAgo = publishDate ? moment(publishDate).fromNow(true) : '';

  const formattedTimeAgo = timeAgo.replace(/^an\s/, '1 ').replace(/^a\s/, '1 ');

  return (
    <div className="p-3 em:p-5 bg-brand-Gray3 rounded-sm border-[1px] border-brand-GrayMain flex flex-col gap-[15px]">
      <div className="flex flex-col em:flex-row justify-start xlg:justify-between gap-5 em:items-center">
        <div className="max-w-[70px] w-full">
          {image && (
            <Image
              alt={image?.alt}
              src={image}
              className="w-[70px] h-[70px] object-cover rounded-sm"
            />
          )}
        </div>
        <div className="flex flex-col  gap-[5px] max-w-full xlg:max-w-[382px] w-full">
          <div className="flex flex-row justify-between gap-5">
            <div>
              {publishDate && (
                <span className="text-xs text-[#A8ACB6] font-sans">
                  {formattedTimeAgo} ago
                </span>
              )}
              {title && (
                <h3 className="text-lg font-medium leading-[139%] text-brand-Black1 font-Mono">
                  {title}
                </h3>
              )}
            </div>
            <div>
              <MoveIcon className="cursor-pointer" />
            </div>
          </div>
          {description && (
            <p className="text-sm text-brand-DarkGray3 font-Mono line-clamp-2">
              {description}
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-row flex-wrap gap-[14px]">
        {categories &&
          categories?.map((category, index) => (
            <div
              key={index}
              className="text-sm text-brand-Orange font-Mono leading-[140%] py-1 px-2 bg-brand-LightOrange rounded"
            >
              {category?.name}
            </div>
          ))}
      </div>
    </div>
  );
};

export default GalleryCard;
