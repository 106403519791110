'use client';
import { DemoListingSectionType } from '@/app/(landing)/lib/types/sectionsType';
import React from 'react';
import RichText from '../Common/richText';
import DownArrow from '../icons/DownArrow';
import Image from 'next/image';
import VectoreImage from '@/public/images/demoBgVector.png';
import VideoPlayer from '../Common/VideoPlayer';

const DemoListingSection = (block: DemoListingSectionType) => {
  const { demoLists, description, title } = block || {};

  return (
    <section className=" py-[120px] md:py-[140px] lg:py-[152px]  flex flex-col items-center justify-center h-full ">
      <div className="container">
        <div className="flex flex-col items-center justify-center gap-[17px]">
          {title && (
            <h3 className="font-Mono font-medium text-center">{title}</h3>
          )}
          {description && (
            <p className="SecondaryDescription font-Mono font-light text-brand-SlateGray1 text-center w-full max-w-[691px]">
              {description}
            </p>
          )}
        </div>
        <div className="mt-[35px] md:mt-[45px] lg:mt-[60px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-3 xl:gap-[19px] gap-y-8">
          {demoLists &&
            demoLists.map((item, index) => {
              const { title, description, video, cta } = item || {};

              return (
                <div
                  key={index}
                  className="relative  bg-brand-GrayMain rounded-lg p-5"
                >
                  <VideoPlayer block={video} />
                  <div className="absolute">
                    <Image
                      src={VectoreImage}
                      alt="img"
                      width={450}
                      height={480}
                      className="object-contain"
                    />
                  </div>
                  <div className="md:p-4 xl:p-5 ">
                    <div className="mt-4 md:mt-[30px]">
                      {title && (
                        <h4 className="PrimarySubtitle font-Mono !font-medium mb-3">
                          {title}
                        </h4>
                      )}
                      {description?.custom_rich_text && (
                        <div className="[&>*]:caption [&>*]:font-Mono [&>*]:!font-normal text-brand-Graphite tracking-[-0.42px] w-full max-w-full lg:max-w-[382px]">
                          <RichText block={description} />
                        </div>
                      )}
                    </div>
                    <div className="mt-4 md:mt-[30px] ">
                      <button className="text-brand-Orange SecondaryDescription font-Mono !font-medium  tracking-[-0.42px] flex items-center gap-2.5">
                        {cta?.label}

                        <DownArrow />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default DemoListingSection;
